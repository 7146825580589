@import '../../styles/common';
@import '~@andes/modal/index';
@import '~@andes/button/index';
@import '~@andes/typography/index';

.s-debug-info {
  background: $andes-bg-primary;
  display: none;

  span {
    color: $andes-error-red-secondary;
    font-size: $font-size-18;
    padding-left: $andes-spacing-4;
  }
}

// Fix Hotjar integration
/* stylelint-disable selector-class-pattern */
._hj_feedback_container {
  ._hj-1Fv4C__Feedback__container {
    z-index: 9;
  }
}

/* stylelint-enable selector-class-pattern */

.s-landing {
  &__remote-module--error {
    text-align: center;
    padding: $andes-spacing-32;
    color: $andes-error-red;
  }
}

.modal-rebrand {

  &__description {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .andes-modal__content {

    .andes-typography--size-l.andes-typography--type-title {
      font-size: 24px;
      line-height: 27px;
    }

    .andes-typography--size-l.andes-typography--type-body {
      font-size: 16px;
      line-height: 20px;
    }
  }
}