@import './styles.shared';
@import '@pog-ui-landings-library/framework/lib/desktop.production';
@import '@pog-ui-landings-library/icons/lib/desktop.production';

.s-debug-info {
  padding: $andes-spacing-24;
}

// FIXME: Alignment styles are forced for multiproduct-wrapper and taxes-hybrid. Fix with lib
.pog-ui-multiproduct-wrapper {
  &__title {
    text-align: center !important;
    margin: 0 auto !important;
  }

  @media (width >=1024px) {
    .pog-ui-multiproduct-wrapper {
      & .andes-landings-layout {
        grid-template-columns: none !important;
      }
    }
  }
}

.pog-ui-taxes-hybrid {
  & .andes-landings-layout {
    grid-template-columns: none !important;
  }
}

.modal-rebrand {
  width: 887px;
  height: auto;
  min-width: 838px;

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    object-fit: contain;
    background-color: #000;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding-left: 486px;
    gap: 8px;
    min-height: 145px;
    justify-content: center;
  }

  &.andes-modal .andes-modal__header {
    padding: 32px 32px 24px 0;

    &__contents {
      height: 112px;
    }
  }

  &.andes-modal .andes-modal__content {
    padding: 0 32px 24px 0;
  }

  &.andes-modal .andes-modal__actions {
    padding: 0 32px 32px 486px;
    height: 130px;
  }
}